window.ancnoc ||= {}

ancnoc.competition =
  setup: ->
    @setup_banner()
    @setup_competition_block()
    @setup_competition_form()

  setup_banner: ->
    return false unless document.querySelector('.competition_banner')

    tl = gsap.timeline(
      scrollTrigger: {
        trigger: '.competition_banner',
        start: 'bottom bottom',
        end: 'bottom top'
        onLeave: (e) => document.querySelector('.competition_banner').style.opacity = 0
        onEnterBack: (e) => document.querySelector('.competition_banner').style.opacity = 1
      }
    )

    tl1 = gsap.timeline()

    tl1.fromTo('.competition_banner h1', {opacity: 0}, {opacity: 1, duration: 2}, 1)
  
  setup_competition_block: ->
    return false unless document.querySelector('.competition_detail_block_content_block')

    tl2 = gsap.timeline(
      scrollTrigger: {
        trigger: '.competition_detail_block_content_block .prize_blocks',
        start: 'top bottom'
      }
    )

    tl2.fromTo('.competition_detail_block_content_block .prize_blocks', {y: 100}, {y: 0, duration: 2})

    tl3 = gsap.timeline(
      scrollTrigger: {
        trigger: '.competition_detail_block_content_block svg',
        start: 'top bottom'
      }
    )

    tl3.fromTo('.competition_detail_block_content_block svg', {y: 100}, {y: 0, duration: 2})
  
  setup_competition_form: ->
    return false unless document.querySelector('.competition_form')

    tl4 = gsap.timeline(
      scrollTrigger: {
        trigger: '.competition_form .competition_answers',
        start: 'top bottom'
      }
    )

    tl4.fromTo('.competition_form .competition_answers', {y: 100}, {y: 0, duration: 2})