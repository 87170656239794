window.ancnoc ||= {}

podcast_links = document.querySelectorAll('.podcast_link')

ancnoc.podcast =
  setup: ->

    return false unless podcast_links.length > 0

    if !document.cookie.match(/ancnoc_cookies_consent=true/)
      podcast_span = document.createElement('p')
      podcast_span.innerHTML = ' We want to give you the best possible experience whilst viewing our website, and to do this we use cookies. Check out our <a href="/cookie-policy">cookie policy</a> for info on cookies and tracking technology. To view this functionality please <a href="/cookies-accepted" class="accept_cookies">accept cookies</a>.'
      podcast_links.forEach (link) ->
        link.after(podcast_span)
    else
      spotify_api = document.createElement('script')
      spotify_api.src = 'https://open.spotify.com/embed-podcast/iframe-api/v1'
      document.querySelector('body').append(spotify_api)

window.onSpotifyIframeApiReady = (IFrameAPI) ->
  options = {}
  
  if window.innerWidth < 850
    options.height = '152'
  else
    options.height = '232'
  
  podcast_links.forEach (link) ->
    if link.href.match(/spotify.com/)
      options.uri = "spotify:episode:#{link.href.match(/episode\/([\w\-\d]+)/)[1]}"
      callback = (EmbedController) => {};
      IFrameAPI.createController(link, options, callback);
