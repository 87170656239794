window.ancnoc ||= {}

window.ancnoc.age_gate =
  setup : ->
    return false if document.cookie.match(/ancnoc_age_verification/) || window.location.pathname.match(/terms-conditions/)

    document.querySelector('body').classList.add('ag')

    document.querySelector('.age_gate_wrapper .button').addEventListener 'click', (e) ->
      e.preventDefault()
      document.cookie = 'ancnoc_age_verification=accepted; path=/'
      document.querySelector('body').classList.remove('ag')