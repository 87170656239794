window.tictoc ||= {}

tictoc.ui =
  setup: ->
    @setup_js_class()
    @scroll_to_anchor()
    @set_content_padding()
    @set_age_gate_position()
    
    gsap.registerPlugin(ScrollTrigger)

  setup_js_class: ->
    document.querySelector('html').classList.remove('no-js')
    document.querySelector('html').classList.add('js')

  scroll_to_anchor: ->
    document.querySelectorAll('.scroll_to_anchor').forEach (el) ->
      el.addEventListener 'click', (e) ->
        e.preventDefault()
        document.querySelector(e.target.hash).scrollIntoView behavior: 'smooth'
  
  set_content_padding: ->
    return false unless document.querySelector('header')
    header_height = document.querySelector('header').offsetHeight
    document.querySelector('#content').style.paddingTop = "#{header_height}px"
  
  set_age_gate_position: ->
    return false unless document.querySelector('.age_gate_wrapper')
    if window.innerHeight < 870
      document.querySelector('.age_gate_wrapper').classList.add('small')