import './vendor/gsap.min.js'
import './vendor/ScrollTrigger.min.js'
import './ancnoc/menu.js.coffee'
import './ancnoc/header.js.coffee'
import './ancnoc/cookie_notice.js.coffee'
import './ancnoc/age_gate.js.coffee'
import './ancnoc/social.js.coffee'
import './ancnoc/video.js.coffee'
import './ancnoc/podcast.js.coffee'
import './ancnoc/custom_checkbox.js.coffee'
import './ancnoc/custom_radio.js.coffee'
import './ancnoc/custom_select.js.coffee'
import './ancnoc/article_filter.js.coffee'
import './ancnoc/overview.js.coffee'
import './ancnoc/competition.js.coffee'
import './tictoc/environment.js.erb'
import './tictoc/preview.js'
import './tictoc/ui.js.coffee'

var ready;

ready = function (callback) {
  if (document.readyState !== 'loading') {
    callback();
  } else if (document.addEventListener) {
    document.addEventListener('DOMContentLoaded', callback);
  } else {
    document.attachEvent('onreadystatechange', function () {
      if (document.readyState === 'complete') {
        callback();
      }
    });
  }
};

ready(function () {
  tictoc.preview.setup();
  tictoc.ui.setup();
  ancnoc.age_gate.setup()
  ancnoc.menu.setup()
  ancnoc.header.setup()
  ancnoc.cookie_notice.setup()
  ancnoc.social.setup()
  ancnoc.video.setup()
  ancnoc.podcast.setup()
  ancnoc.custom_select.setup()
  ancnoc.custom_checkbox.setup()
  ancnoc.custom_radio.setup()
  ancnoc.article_filter.setup()
  ancnoc.overview.setup()
  ancnoc.competition.setup()
});