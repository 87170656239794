class CustomCheckbox
  constructor: (@checkbox) ->
    checkbox_label = @checkbox.parentNode

    checkbox_label.classList.add('custom_checkbox_label')

    @checkbox_wrapper = document.createElement('div')
    @checkbox_wrapper.classList.add('custom_checkbox_wrapper')

    @checkbox.parentElement.insertBefore(@checkbox_wrapper, @checkbox)
    @checkbox_wrapper.appendChild(@checkbox)

    @update_wrapper_value()
    @add_listeners()

  add_listeners: ->
    @checkbox.addEventListener 'change', =>
      @update_wrapper_value()
    
    @checkbox.addEventListener 'focus', =>
      @checkbox_wrapper.setAttribute('data-focus', true)
    
    @checkbox.addEventListener 'blur', =>
      @checkbox_wrapper.removeAttribute('data-focus')

  update_wrapper_value: =>
    if @checkbox.checked
      @checkbox_wrapper.classList.add('checked')
    else
      @checkbox_wrapper.classList.remove('checked')

window.ancnoc ||= {}

ancnoc.custom_checkbox =
  setup: ->
    document.querySelectorAll('[type=checkbox]').forEach (checkbox) ->
      custom_checkbox = new CustomCheckbox(checkbox)