window.ancnoc ||= {}

ancnoc.overview =
  setup: ->
    @add_listeners()

  add_listeners: ->
    overview_children = document.querySelectorAll('.overview_child')

    return false unless overview_children.length
    
    tl = gsap.timeline(
      scrollTrigger: {
        trigger: '.overview_children',
        start: 'top center'
      }
    )

    tl.fromTo('.overview_child', {opacity: 0, y: 200}, {opacity: 1, y: 0, duration: 3, ease: "power4.out"})