window.ancnoc ||= {}

ancnoc.social =
  setup: ->
    @social_shares()

  social_shares: ->
    return unless document.querySelectorAll('.social_share_link').length

    document.querySelectorAll('.social_share_link').forEach (link) ->
      link.addEventListener 'click', (e) ->
        e.preventDefault()
        window_height = switch
          when link.classList.contains('facebook') then 400
          when link.classList.contains('twitter') then 445

        if link.classList.contains('copy_to_clipboard')
          if navigator && navigator.clipboard && navigator.clipboard.writeText
            navigator.clipboard.writeText(link.getAttribute('href'))
            link.classList.add('copied')
            setTimeout ->
              link.classList.remove('copied')
            , 5000
        else
          window.open link.getAttribute('href'), 'social_share', "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=#{window_height},width=600"
