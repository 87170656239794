window.ancnoc ||= {}

ancnoc.header =
  setup: ->
    return false unless document.querySelector('header')
    @add_listeners()

  add_listeners: ->
    tl = gsap.timeline(
      scrollTrigger: {
        trigger: 'body',
        start: 'top top',
        toggleActions: 'play play none reverse',
        onEnter: (e) => document.querySelector('header').classList.add('moved'),
        onLeaveBack: (e) => document.querySelector('header').classList.remove('moved')
      }
    )

    if window.innerWidth > 849
      tl.to('header .logo', {duration: 0.4, height: 22}, 0)
      tl.to('header', {duration: 0.4, paddingBottom: 0, paddingTop: 18}, 0)